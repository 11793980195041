import "../styles/main.scss";
import splt from "spltjs";
import anime from "animejs"
import ScrollMagic from "scrollmagic/scrollmagic/minified/ScrollMagic.min.js"
import ScrollingCanvas from './scrollingCanvas'
// import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";

splt({})

export const controller = new ScrollMagic.Controller();

const isMobile = window.innerWidth < 600 ? true : false

const loaderEntryAnim = anime({
	targets: '.loader-text .char, .loader-spinner',
	translateY: [4, 0],
	opacity: [0, 1],
	delay: anime.stagger(10),
	easing: 'cubicBezier(.71,-0.77,.43,1.67)',
})

const grid = [11, 7]

const colorBlocksAnim = anime.timeline({
	easing: 'easeInOutSine',
	autoplay: false
}).add({
	targets: '.color-block',
	translateX: [() => anime.random(-200, 200), 0],
	translateY: [anime.stagger([anime.random(-10, -60), anime.random(-600, -800)], { grid: grid, from: 'center' }), 0],
	width: (el) => [isMobile ? "100%" : el.offsetHeight, "100%"],
	scale: [0, 0.9],
	borderRadius: ['50%', '0.375rem'],
	backgroundColor: (el) => ["#ffffff", el.style.backgroundColor],
	rotate: [anime.stagger([90, 0], { grid: grid, from: 'last' }), 0],
	delay: anime.stagger(10, { from: 'last' }),
}).add({
	targets: '.color-block > span',
	duration: 200,
	opacity: [0, 1],
	delay: anime.stagger(100, { from: 'center' })
}, 1000)

const colorBlocksScene = new ScrollMagic.Scene({
	duration: isMobile ? (window.innerHeight * 0.6) : 1000,
	triggerElement: document.querySelector('.color-pallete'),
	triggerHook: 0.9,
	offset: isMobile ? 0 : -200
})
	// .addIndicators()
	.addTo(controller)


colorBlocksScene.on("update", (e) => colorBlocksAnim.seek(e.currentTarget.progress() * colorBlocksAnim.duration))

function ScrollTo(element, duration) {
	const startingY = window.pageYOffset
	const elementY = window.pageYOffset + document.querySelector(element).getBoundingClientRect().top
	const targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY
	const diff = targetY - startingY
	const easeOutCubic = (t) => 1 - Math.pow(1 - t, 3)

	let start
	if (!diff) return

	window.requestAnimationFrame(function step(timestamp) {
		if (!start) start = timestamp
		const time = timestamp - start
		let percent = Math.min(time / duration, 1)

		percent = easeOutCubic(percent)
		window.scrollTo(0, startingY + diff * percent)

		if (time < duration) window.requestAnimationFrame(step)
	})
}

if (!isMobile) {
	const headerVideoScene = new ScrollMagic.Scene({
		duration: window.innerHeight,
		triggerElement: document.querySelector('header'),
		triggerHook: 1,
		offset: 0
	})
		.addTo(controller)

	headerVideoScene.on("update", (e) => anime.set('.header-video', {
		translateY: (e.currentTarget.progress() * 350) - 100
	}))
}

document.addEventListener('readystatechange', event => {

	if (event.target.readyState === 'complete') {
		console.log("readystate: complete")

		ScrollingCanvas('logo', 100, {
			duration: window.innerHeight * 0.6,
			offset: 0.4 * document.querySelector('#logo-canvas').offsetHeight
		})

		setTimeout(() => {
			ScrollTo('header.pad', 1200)
			document.body.classList.remove("loading")
			document.querySelector('.header-video').play()
			anime({
				targets: ".loader-spinner",
				opacity: 0,
				duration: 1200,
				easing: "easeInOutSine"
			})
		}, 1000);
	}
});

window.onload = () => {
	console.log("window: onload")
}