import ScrollMagic from "scrollmagic/scrollmagic/minified/ScrollMagic.min.js"
// import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";
import { controller } from "./main";

function testWebP() {
	return new Promise(res => {
		const webP = new Image();
		webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
		webP.onload = webP.onerror = () => {
			res(webP.height === 2);
		};
	})
};


const ScrollingCanvas = (slug, frameCount, options) => {

	const canvas = document.getElementById(`${slug}-canvas`)
	const context = canvas.getContext("2d")
	const aspectRatio = canvas.width / canvas.height

	canvas.width = canvas.parentElement.offsetWidth
	canvas.height = canvas.width / aspectRatio
	context.imageSmoothingEnabled = false

	testWebP().then(hasWebP => {

		const format = hasWebP ? 'webp' : 'png'

		console.log(format)

		const baseurl = `https://cdn-imgix-open.headout.com/brand-page/assets/`
		const params = `?fm=${format}&w=${canvas.width}&h=${canvas.height}&fit=fill&q=40`

		const currentFrame = index => baseurl + `${slug}-sequence/${slug}${(index - 1).toString().padStart(3, '0')}.png` + params

		const img = new Image()
		img.src = currentFrame(1)
		img.onload = () => context.drawImage(img, 0, 0, canvas.width, canvas.height)

		const updateImage = index => {
			img.src = currentFrame(index)
			context.drawImage(img, 0, 0, canvas.width, canvas.height)
		}

		const preloadImages = () => {
			for (let i = 1; i < frameCount; i++) {
				const img = new Image();
				img.src = currentFrame(i);
			}
		};

		preloadImages()

		const scene = new ScrollMagic.Scene({
			...options,
			triggerHook: 0.9,
			triggerElement: canvas,
		})
			.addTo(controller)

		scene.on("update", (e) => {

			const frameToSet = Math.floor(e.currentTarget.progress() * frameCount)
			const frameIndex = Math.min(frameCount - 1, frameToSet);
			requestAnimationFrame(() => updateImage(frameIndex + 1))
		})

	});
}

export default ScrollingCanvas